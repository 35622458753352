import React from "react";

import FlatButton from "@deprecated/material-ui/FlatButton";
import clsx from "clsx";
import { omit } from "lodash";

/**
 * Custom button themed as material-ui flat.
 */
const Button = (props) => {
  const { children, className, disabled, primary, secondary, style, warning } =
    props;

  const classes = clsx(
    "sde-button",
    primary ? "sde-button-primary" : "",
    secondary ? "sde-button-secondary" : "",
    warning ? "sde-button-warning" : "",
    className
  );
  return (
    <FlatButton
      {...omit(props, ["warning"])}
      className={classes}
      style={{
        color: null,
        fontFamily: null,
        fontSize: null,
        backgroundColor:
          (primary || warning) && disabled
            ? "rgba(224, 224, 224, 1)"
            : "transparent",
        minWidth: "64px",
        ...style,
      }}
    >
      {children}
    </FlatButton>
  );
};

export default Button;
