import React from "react";
import { connect } from "react-redux";
import { gettext } from "django-i18n";

import Form from "../form";
import { Formik, Field } from "../form/Formik";
import Error from "../form/error";
import { renderTextField, renderMultiLineTextField } from "../form/Field";

import { createSupportRequest } from "../../actions";
import {
  combineValidators,
  email,
  maxLength,
  required,
} from "../../validations";

/**
 * Inner form component for the SupportRequest component.
 */
class SupportRequestForm extends React.Component {
  submit(values) {
    const { dispatch } = this.props;
    return dispatch(createSupportRequest.submit(values));
  }

  render() {
    const { initialValues, onCancel } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={this.submit.bind(this)}>
        {({ errors, handleSubmit, isSubmitting }) => (
          <Form
            title={gettext("Send a support request")}
            submitLabel={gettext("Send")}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            isLoading={isSubmitting}
            width={400}
          >
            <Field
              name="name"
              component={renderTextField}
              validate={combineValidators([required, maxLength(64)])}
              floatingLabelText={gettext("Your Name")}
              autoFocus
            />
            <Field
              name="email"
              component={renderTextField}
              validate={combineValidators([required, email, maxLength(255)])}
              floatingLabelText={gettext("Email")}
            />
            <Field
              name="subject"
              component={renderTextField}
              validate={combineValidators([required, maxLength(255)])}
              floatingLabelText={gettext("Subject")}
            />
            <Field
              name="description"
              component={renderMultiLineTextField}
              validate={required}
              floatingLabelText={gettext("Description")}
              rowsMax={10}
            />
            <Error>{errors._error}</Error>
          </Form>
        )}
      </Formik>
    );
  }
}

SupportRequestForm.defaultProps = {
  initialValues: {
    name: "",
    email: "",
    subject: "",
    description: "",
  },
};

export default connect()(SupportRequestForm);
