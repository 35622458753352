import { gettext } from "django-i18n";
import React from "react";
import waffle from "_common/waffle";

/**
 * Global site footer.
 */
const Footer = ({ releaseName }) => {
  const year = new Date().getFullYear();
  let release = "";
  let company = "";
  let engine = "";
  if (!waffle.flag_is_active("ENABLE_SD_BLUEPRINT")) {
    // SD Elements
    release = gettext("SD Elements v%s").format(releaseName);
    company = "SD Elements Inc.";
    if (waffle.flag_is_active("ENABLE_THREATS_ENGINE")) {
      engine = "-te";
    }
  }
  return (
    <footer className="sde-footer">
      <div className="sde-container">
        <section className="sde-footer-copyright">
          {gettext("%s %s © %s %s All rights reserved.")
            .format(release, engine, year, company)
            .replace(/\s+/g, " ")
            .trim()}
        </section>
      </div>
    </footer>
  );
};

export default Footer;
