import React from "react";
import { noop } from "lodash";

export interface IDialogContext {
  setDirty: (value: boolean) => void;
}

export const DialogContext = React.createContext<IDialogContext>({
  setDirty: noop,
});
