/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldValidator } from "formik";

interface SdeFieldValidator extends FieldValidator {
  message(customMessage: String): FieldValidator;
}

interface ValidatorPredicate {
  (value: any): boolean;
}

export const createValidator = (
  predicate: ValidatorPredicate,
  defaultMessage: String
): SdeFieldValidator => {
  const _createValidator =
    (msg: String): FieldValidator =>
    (value: any) =>
      predicate(value) ? undefined : msg.toString();
  const validator = _createValidator(defaultMessage) as any;
  validator.message = (customMessage: String) =>
    _createValidator(customMessage);
  return validator as SdeFieldValidator;
};

export const combineValidators =
  (validators: FieldValidator[]) => (value: any) =>
    validators.map((validator) => validator(value)).find((result) => result);
