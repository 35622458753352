import React, { CSSProperties, ReactNode } from "react";

import {
  SelectField as MaterialSelectField,
  SelectFieldProps as MaterialSelectFieldProps,
} from "@deprecated/material-ui";

import { getTextFieldStyleProps } from "../TextField/utils";

export interface SelectFieldProps extends MaterialSelectFieldProps {
  children?: ReactNode;
  floatingLabelShrinkStyle?: CSSProperties;
}

const mergePropsWithDefaults = (props: SelectFieldProps) => ({
  ...props,
  ...getTextFieldStyleProps(props),
});

/**
 * Custom input component that displays a fixed list of choices in a dropdown.
 */
const SelectField = React.forwardRef(
  (props: SelectFieldProps, ref: React.Ref<MaterialSelectField>) => (
    <MaterialSelectField ref={ref} {...mergePropsWithDefaults(props)} />
  )
);

SelectField.defaultProps = {
  fullWidth: true,
};

export default SelectField;
