import React from "react";
import { gettext } from "django-i18n";
import Ink from "react-ink";
import clsx from "clsx";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import ArrowDropRight from "@deprecated/material-ui/svg-icons/navigation-arrow-drop-right";
import Divider from "@deprecated/material-ui/Divider";
import FileDownload from "@deprecated/material-ui/svg-icons/file/file-download";
import MailOutline from "@deprecated/material-ui/svg-icons/communication/mail-outline";
import OpenInNew from "@deprecated/material-ui/svg-icons/action/open-in-new";

import Tooltipify from "_common/components/tooltipify";
import Highlight from "_common/components/highlight";

import NavItem from "./nav-item";

/**
 * Particle component used inside header nav dropdowns.
 */
export const DropDownMenuItem = (props) => {
  const { item, target, children } = props;

  const classes = clsx(
    "sde-header-navigation-dropdown-item",
    { "sde-header-navigation-dropdown-holder": props.children },
    props.className
  );

  function renderIcon(icon) {
    const Icon = {
      open: OpenInNew,
      mail: MailOutline,
      file: FileDownload,
    }[icon];

    if (Icon) {
      return <Icon className="sde-header-navigation-dropdown-item-icon" />;
    }
    return null;
  }

  let rel = null;

  if (item.target === "_blank") {
    rel = "noopener noreferrer";
  }

  let highlightFallback;

  if (item.beta) {
    highlightFallback = (
      <span
        style={{
          paddingBottom: "10px",
          fontSize: "10px",
        }}
      >
        ({gettext("BETA")})
      </span>
    );
  }

  return (
    <NavItem item={item}>
      <div className={classes} aria-label={gettext("Dropdown Menu Item")}>
        <a href={item.link} target={item.target || target} rel={rel}>
          <Tooltipify
            message={item.label}
            style={{ zIndex: 9999 }}
            showOnEllipsis
          >
            <span className="sde-header-navigation-dropdown-item-label">
              {item.label}
            </span>
          </Tooltipify>
          {renderIcon(item.icon)}
          <Highlight
            highlight={item.highlight}
            perm={item.highlightPerm}
            fallback={highlightFallback}
          >
            <span className="sde-header-navigation-dropdown-item-marker">
              {gettext("New")}
            </span>
          </Highlight>

          {children && <ArrowDropRight />}
          <Ink />
        </a>

        {children}
      </div>
    </NavItem>
  );
};

/**
 * Special dropdown used in the header navigation.
 */
class DropDownMenu extends React.Component {
  renderDropDownItem(item, index) {
    const { target } = this.props;

    if (item.divider) {
      return <Divider key={index} />;
    }

    if (item.title) {
      return (
        <div key={item.title} className="sde-header-navigation-dropdown-title">
          {item.title}
        </div>
      );
    }

    return (
      <DropDownMenuItem key={NavItem.key(item)} item={item} target={target}>
        {this.renderDropDown(item.children)}
      </DropDownMenuItem>
    );
  }

  renderDropDown(items) {
    if (isEmpty(items)) {
      return null;
    }

    const firstItemIsTitle = items.length && items[0].title;

    const classes = clsx("sde-header-navigation-dropdown", {
      "sde-header-navigation-dropdown-offset": firstItemIsTitle,
    });

    return (
      <div className={classes}>
        <div className="sde-header-navigation-dropdown-menu">
          {items.map(this.renderDropDownItem, this)}
        </div>
      </div>
    );
  }

  render() {
    return this.renderDropDown(this.props.items);
  }
}

DropDownMenu.propTypes = {
  items: PropTypes.array,
  target: PropTypes.string,
};

DropDownMenu.defaultProps = {
  items: [],
};

export default DropDownMenu;
