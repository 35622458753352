import React from "react";

/**
 * Actual tooltip component used by tooltipify.
 */
const Tooltip = React.forwardRef(
  ({ show, interactive, mouseEnter, mouseLeave, ...others }, ref) => {
    if (!show) {
      return null;
    }
    if (interactive) {
      return (
        <div
          className="sde-tooltip sde-tooltip-interactive"
          role="tooltip"
          data-cy="tooltip"
          ref={ref}
          {...others}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        />
      );
    }
    return (
      <div
        className="sde-tooltip"
        role="tooltip"
        data-cy="tooltip"
        ref={ref}
        {...others}
      />
    );
  }
);

export { Tooltip };
