/* eslint no-restricted-globals: "off" */

// Package web worker script into a blob that can be imported and used.
// Normally web worker scripts require you to specify a url path to a Javascript file
// This helps get around it to make web worker scripts easier to initialize and use.
const workercode = () => {
  self.onmessage = (e) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // Send back read file
      self.postMessage(reader.result);
    };
    reader.onabort = () => {
      self.postMessage(null);
    };

    reader.onerror = reader.onabort;

    reader.readAsText(e.data);
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const workerScript = URL.createObjectURL(blob);

export default workerScript;
