import React, { useState } from "react";
import { gettext } from "django-i18n";

import { makeStyles } from "@mui/styles";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import ForumIcon from "@mui/icons-material/Forum";

import Chat from "./Chat";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    bottom: 20,
    right: 34,
  },
}));

export default function ChatAssistant() {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip
        title={gettext("Open Navigator")}
        onClick={() => setIsOpen(!isOpen)}
      >
        <IconButton
          className={classes.root}
          aria-label={gettext("Open Navigator") as string}
          size="large"
        >
          <ForumIcon />
        </IconButton>
      </Tooltip>
      {isOpen && (
        <Chat
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}
