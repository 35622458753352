import React from "react";
import classnames from "classnames";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";

import ChatRecommendation from "./ChatRecommendation";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
  },
}));

export default function ChatRecommendations({
  recommendations,
  onSelection,
}: {
  recommendations: { label?: String; prompt: String }[];
  onSelection: (selection: string | String) => void | Promise<void>;
}) {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.root)}>
      {recommendations.map((recommendation) => (
        <ChatRecommendation
          key={recommendation.prompt as React.Key}
          content={recommendation.label || recommendation.prompt}
          onClick={() => {
            onSelection(recommendation.prompt);
          }}
        />
      ))}
    </Box>
  );
}
