import { gettext } from "django-i18n";
import { isEmpty } from "lodash";
import React from "react";

import Ink from "react-ink";

import SearchIcon from "@deprecated/material-ui/svg-icons/action/search";

import clsx from "clsx";
import Loader from "../loader";
import Search from "../search";
import Tooltipify from "../tooltipify";

/**
 * A Header component that can display a search icon and search bar.
 */
class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      isSearching: false,
    };
  }

  openSearch() {
    this.setState({ isSearching: true });
  }

  closeSearch = () => {
    this.setState({ isSearching: false });
  };

  render() {
    const {
      className,
      actions,
      backToTooltipLabel,
      disableSearch,
      imageUrl,
      imageUrlStyle,
      isLoading,
      inlineStyle,
      onSearchChange,
      query,
      search,
      searchDebounce,
      searchHint,
      searchTooltip,
      subtitle,
      subtitleStyle,
      subtitleComponent,
      subtitleTooltip,
      subtitleTooltipStyle,
      title,
      titleExtras,
      titleAside,
      titleTooltip,
      titleStyle,
      tooltip,
    } = this.props;

    let subtitleElement = null;

    if (subtitle) {
      subtitleElement = (
        <h4 style={subtitleStyle} className="sde-paper-subtitle">
          {subtitle}
        </h4>
      );

      if (subtitleTooltip) {
        subtitleElement = (
          <Tooltipify style={subtitleTooltipStyle} message={subtitleTooltip}>
            {subtitleElement}
          </Tooltipify>
        );
      }
    }

    let titleContents = (
      <>
        <Tooltipify message={title} showOnEllipsis>
          <h3
            className="sde-paper-title"
            style={titleStyle}
            data-cy="paper-title"
          >
            {title} {titleExtras}
          </h3>
        </Tooltipify>
        {titleTooltip}
      </>
    );

    if (tooltip) {
      titleContents = (
        <Tooltipify message={tooltip}>{titleContents}</Tooltipify>
      );
    }

    subtitleElement = subtitleComponent || subtitleElement;

    if (this.state.isSearching) {
      return (
        <header className="sde-paper-header sde-paper-header-alternative">
          <Search
            onChange={onSearchChange}
            handleBack={this.closeSearch}
            backToTooltipLabel={backToTooltipLabel}
            value={query && query.search}
            searchHint={searchHint}
            searchDebounce={searchDebounce}
          />
          <Loader loading={isLoading} />
        </header>
      );
    }

    const searchAction = search ? (
      <Tooltipify message={searchTooltip} key="search">
        <button
          key="search"
          data-cy="search-button"
          aria-label={gettext("Search")}
          disabled={disableSearch}
          onClick={this.openSearch.bind(this)}
          style={{
            overflow: "visible",
            height: "30px",
            width: "30px",
          }}
          type="button"
        >
          <SearchIcon />
          <Ink />
          {query && !isEmpty(query.search) && (
            <span className="sde-action-count-badge">1</span>
          )}
        </button>
      </Tooltipify>
    ) : null;

    return (
      <header className={clsx("sde-paper-header", className)} data-cy={title}>
        {imageUrl && (
          <img src={imageUrl} width="24" height="24" style={imageUrlStyle} />
        )}
        {titleContents} {titleAside}
        <span
          className="sde-paper-title-actions sde-paper-title-actions-right"
          style={inlineStyle}
        >
          {actions}
          {searchAction}
        </span>
        {subtitleElement}
        <Loader loading={isLoading} />
      </header>
    );
  }
}

Header.defaultProps = {
  searchTooltip: gettext("Search"),
  inlineStyle: {},
  imageUrlStyle: { verticalAlign: "middle", marginRight: "12px" },
  dataLocatorValue: "header",
};

export default Header;
