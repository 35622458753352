import React from "react";
import clsx from "clsx";

export interface PaperProps extends React.ComponentPropsWithoutRef<"article"> {
  width?: React.CSSProperties["width"];
  "data-cy"?: string;
}

/**
 * Material themed holder used to display content in a pleasant white box.
 */
const Paper = React.forwardRef(
  (props: PaperProps, ref: React.Ref<HTMLElement>) => (
    <article
      data-cy={props["data-cy"]}
      ref={ref}
      className={clsx("sde-paper", props.className)}
      style={{ width: props.width, ...props.style }}
    >
      {props.children}
    </article>
  )
);

export default Paper;
