import React from "react";

import Code from "_common/components/code";
import Markdown from "./Markdown";

/**
 * Markdown component that adds syntax-highlighting
 *
 * Separate component, because highlight.js is a heavy dependency
 */
const EnhancedMarkdown = ({
  renderers = null,
  disableMargin = false,
  ...otherProps
}) => (
  <Markdown
    {...otherProps}
    renderers={{
      ...renderers,
      code: (props) => (
        <Code
          source={props.value}
          language={props.language}
          disableMargin={disableMargin}
        />
      ),
      inlineCode: (props) => <Code inline source={props.value} />,
    }}
  />
);

export default EnhancedMarkdown;
