import React from "react";
import { Link, matchPath } from "react-router-dom";
import clsx from "clsx";
import { isEmpty } from "lodash";
import ArrowIcon from "@deprecated/material-ui/svg-icons/navigation/arrow-drop-down";

import Highlight from "_common/components/highlight";
import NavItem from "./nav-item";

const isSelected = (props) => {
  const { item, selected } = props;
  const { to, href, selectedPath, calculateSelected } = item;

  const { pathname } = window.location;
  const destination = to || href;

  let match = pathname === destination;

  if (selectedPath) {
    match = matchPath(pathname, {
      path: selectedPath,
      exact: true,
    });
  }

  if (calculateSelected) {
    const matcher = (path) =>
      matchPath(pathname, {
        path,
        exact: true,
      });

    match = calculateSelected(matcher);
  }

  if (match) {
    return true;
  }

  return selected;
};

/**
 * Particle component used to render primary nav items.
 * Also contains the dropdowns for child nav items.
 */
const Item = (props) => {
  const { item, target, className, children, icon } = props;

  const classes = clsx(
    "sde-header-navigation-item",
    { "sde-header-navigation-dropdown-holder": children },
    { "is-icon": icon },
    { "is-selected": isSelected(props) },
    className
  );

  let link;
  let indicator;

  if (!isEmpty(props.children)) {
    indicator = (
      <ArrowIcon className="sde-header-navigation-dropdown-indicator" />
    );
  }

  const linkProps = {
    className: "sde-header-navigation-item-link",
    href: item.link,
    to: item.to,
    title: item.label,
    target: item.target || target,
  };

  const linkChildren = (
    <span
      data-cy="header-navigation-item-label"
      className="sde-header-navigation-item-label"
    >
      {icon || item.label}
      {indicator}

      {item.highlight && (
        <Highlight highlight={item.highlight} perm={item.highlightPerm}>
          <span className="sde-header-navigation-item-marker" />
        </Highlight>
      )}
    </span>
  );

  if (linkProps.to) {
    link = <Link {...linkProps}>{linkChildren}</Link>;
  } else {
    link = <a {...linkProps}>{linkChildren}</a>;
  }

  return (
    <NavItem item={item} key={NavItem.key(item)}>
      <div className={classes} tabIndex="0">
        {link}
        {children}
      </div>
    </NavItem>
  );
};

export default Item;
