import React from "react";
import classnames from "classnames";
import { gettext } from "django-i18n";

import { makeStyles } from "@mui/styles";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import EnhancedMarkdown from "_common/components/markdown/EnhancedMarkdown";

const useStyles = makeStyles(() => ({
  root: {
    width: "fit-content",
    maxWidth: "100%",
    gap: "0.375rem 0.5rem",
    background: "#fdf0e1",
    borderRadius: 4,
    display: "flex",
    padding: 5,
  },
  typeThem: {
    background: "#e3effb",
  },
  avatar: {
    margin: 5,
    marginRight: 0,
  },
  text: {
    flex: 1,
    padding: 5,
    "& .sde-markdown-code-box": {
      maxWidth: 440,
    },
    "& code": {
      whiteSpace: "unset !important",
    },
  },
  name: {
    fontWeight: 800,
    marginBottom: 3,
  },
}));

function ChatMessage({
  role,
  content,
}: {
  role: "function" | "user" | "data" | "system" | "assistant" | "tool";
  content: string;
}) {
  const classes = useStyles();

  let name: string = role;

  if (role === "user") {
    name = window?.django?.user
      ? `${window.django.user.first_name} ${window.django.user.last_name}`
      : (gettext("User") as string);
  } else if (role === "assistant") {
    name = gettext("Navigator") as string;
  }

  const initials = name
    .split(" ")
    .map((n) => n[0].toUpperCase())
    .join("")
    .slice(0, 2);

  return (
    <Box
      className={classnames(classes.root, {
        [classes.typeThem]: role !== "user",
      })}
    >
      <Avatar
        className={classes.avatar}
        alt={role}
        variant="rounded"
        sx={{ height: 36, width: 36, fontSize: 18 }}
      >
        {initials}
      </Avatar>

      <Box className={classes.text}>
        <Typography className={classes.name} variant="body2">
          {name}
        </Typography>
        <Typography variant="body2" component="div">
          <EnhancedMarkdown source={content} openLinksInNewTab />
        </Typography>
      </Box>
    </Box>
  );
}

// Prevents re-render if not needed
export default React.memo(ChatMessage);
