import React from "react";
import { gettext } from "django-i18n";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";

const models = [
  {
    value: "gpt-4o",
    label: gettext("GPT-4o (Latest)"),
  },
  {
    value: "gpt-4-turbo",
    label: gettext("GPT-4 Turbo"),
  },
  {
    value: "gpt-4",
    label: gettext("GPT-4"),
  },
  {
    value: "gpt-3.5-turbo",
    label: gettext("GPT-3.5 Turbo"),
  },
];

const assistants = [
  {
    value: "v5.2",
    label: gettext("Conjure v5.2 (Latest)"),
  },
  {
    value: "v4.1",
    label: gettext("Conjure v4.1"),
  },
];

export const defaultSettings = {
  model: models[0].value,
  assistant: assistants[0].value,
  instructions: "",
};

type Settings = {
  model: string;
  assistant: string;
  instructions?: string;
};

export default function ChatSettings({
  settings,
  onSettingsChange,
}: {
  settings: Settings;
  onSettingsChange: (newSettings: Settings) => void;
}) {
  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        padding: 3,
      }}
      noValidate
      autoComplete="off"
    >
      <Alert severity="info" sx={{ marginBottom: 4 }}>
        {gettext(
          "Additional instructions are automatically saved to local storage"
        )}
      </Alert>

      <TextField
        select
        label="Model"
        value={settings.model}
        fullWidth
        sx={{ marginBottom: 4 }}
        onChange={(e) => {
          onSettingsChange({ ...settings, model: e.target.value });
        }}
      >
        {models.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label={gettext("Assistant Version")}
        value={settings.assistant}
        fullWidth
        sx={{ marginBottom: 4 }}
        onChange={(e) => {
          onSettingsChange({ ...settings, assistant: e.target.value });
        }}
      >
        {assistants.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label={gettext("Additional Instructions")}
        InputLabelProps={{ shrink: true }}
        placeholder={
          gettext(`ex. Modify the following aspects of your behaviour:

* Code examples should be in Typescript
* Ask before looking up task notes
* Use funny puns`) as string
        }
        value={settings.instructions}
        fullWidth
        multiline
        maxRows={10}
        minRows={4}
        onChange={(e) => {
          onSettingsChange({ ...settings, instructions: e.target.value });
        }}
      />
    </Box>
  );
}
