import React from "react";
import { connect } from "react-redux";

import CheckPerms from "_common/components/check-perms";

/**
 * Displays a component if a navigation URL has not been visited yet
 */
const Highlight = ({ children, fallback, highlight, perm, seen }) => {
  if (!highlight || highlight(seen)) {
    return fallback;
  }

  return <CheckPerms requiredPermission={perm}>{children}</CheckPerms>;
};

Highlight.defaultProps = {
  fallback: null,
};

export default connect((state) => ({
  seen: state.highlights.seen,
}))(Highlight);
