import React from "react";
import { connect } from "react-redux";
import { gettext } from "django-i18n";

import FlatButton from "@deprecated/material-ui/FlatButton";

import BugReportIcon from "@deprecated/material-ui/svg-icons/action/bug-report";

import SupportRequestForm from "./SupportRequestForm";
import Dialog from "../dialog";

import { createSupportRequest, clearAllIntents } from "../../actions";

/**
 * Allows a user to send a message to support from any page.
 */
class SupportRequest extends React.Component {
  onClick = () => {
    const { dispatch } = this.props;
    dispatch(createSupportRequest.intent());
  };

  render() {
    const { dispatch, intents } = this.props;

    const children = (
      <SupportRequestForm onCancel={() => dispatch(clearAllIntents())} />
    );

    const contentStyle = {
      width: "auto",
      maxWidth: "400px",
      position: "fixed",
      left: null, // Override the default left CSS attribute so we can use the right CSS attribute
      right: "24px",
      bottom: "18px",
      transform: "translateX(0%)",
    };

    const supportRequestDialog = (
      <Dialog
        contentStyle={contentStyle}
        isDirty={false}
        modal={false}
        open={intents[createSupportRequest]}
        onRequestClose={() => dispatch(clearAllIntents())}
      >
        {children}
      </Dialog>
    );

    const buttonStyle = {
      borderRadius: "5px",
      minWidth: "48px",
      minHeight: "40px",
      color: "#ffffff",
      padding: "0 10px",
    };

    return (
      <div>
        {supportRequestDialog}
        <div className="sde-support-button">
          <FlatButton
            data-cy="support-button"
            aria-label={gettext("Contact Support")}
            backgroundColor="#3f3f4c"
            hoverColor="#3f3f4c"
            onClick={this.onClick}
            style={buttonStyle}
            icon={<BugReportIcon color="#ffffff" />}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  intents: state.intents,
});

export default connect(mapStateToProps)(SupportRequest);
