import React, { Component } from "react";
import { gettext } from "django-i18n";

import MaterialDialog from "@deprecated/material-ui/Dialog";
import ClearIcon from "@deprecated/material-ui/svg-icons/content/clear";

import EventListener from "react-event-listener";

import clsx from "clsx";
import IconButton from "../buttons/icon";
import { DialogContext } from "./DialogContext";

/**
 * Component which can show other compontents in a floating box. Usually used for forms.
 */
class Dialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
    };

    this.dialogContext = {
      setDirty: (value) => this.setState({ dirty: value || false }),
    };
  }

  handleRequestClose = () => {
    const { isDirty, onRequestClose, confirmClose } = this.props;
    const { dirty } = this.state;

    if ((isDirty || dirty) && confirmClose) {
      // eslint-disable-next-line no-alert
      const confirmResponse = window.confirm(
        gettext("Leaving this page will discard unsaved changes.")
      );

      if (!confirmResponse) {
        return;
      }
    }

    if (onRequestClose) {
      onRequestClose();
    }
  };

  handleKeyDown = (event) => {
    if (event.keyCode !== 8) {
      return;
    }

    const blackList = ["BODY", "BUTTON", "DIV", "SVG", "SPAN", "UL", "LI", "A"];

    // Gets the source element of the backspace
    // Each browser has its own version of this, which is why we have 2
    const srcElement = event.srcElement || event.target;

    if (srcElement && blackList.includes(srcElement.tagName.toUpperCase())) {
      event.preventDefault();
    }
  };

  render() {
    const {
      children,
      className,
      contentStyle,
      showCloseButton,
      open,
      ...others
    } = this.props;

    if (!open) {
      return null;
    }

    const closeButton = (
      <IconButton
        className="sde-dialog-close"
        style={{
          position: "absolute",
          right: 0,
          width: "64px",
          height: "64px",
        }}
        onClick={this.handleRequestClose}
      >
        <ClearIcon />
      </IconButton>
    );
    const mergedContentStyle = {
      width: "auto",
      maxWidth: "930px",
      position: "absolute",
      left: "50%",
      top: 50,
      transform: "translateX(-50%)",
      ...contentStyle,
    };

    return (
      <MaterialDialog
        className={clsx("sde-dialog", className)}
        contentStyle={mergedContentStyle}
        {...others}
        onRequestClose={this.handleRequestClose}
        title={null}
        open
      >
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        {showCloseButton && closeButton}
        <DialogContext.Provider value={this.dialogContext}>
          {children}
        </DialogContext.Provider>
      </MaterialDialog>
    );
  }
}

Dialog.defaultProps = {
  confirmClose: true,
  modal: true,
  open: false,
  autoScrollBodyContent: true,
  bodyStyle: {
    padding: 0,
    paddingTop: 0,
  },
  contentStyle: {},
};

export default Dialog;
