import React from "react";
import { Message } from "ai/react";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import ChatMessage from "./ChatMessage";
import ChatRecommendations from "./ChatRecommendations";
import ChatWarning from "./ChatWarning";

const useStyles = makeStyles(() => ({
  root: {
    flex: "1",
    position: "relative",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column-reverse",
  },
  messages: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    gap: 10,
  },
}));

function ChatMessagesList({
  messages,
  isLoading,
  error,
  recommendations,
  onRecommendationSelection,
}: {
  messages: Message[];
  isLoading: boolean;
  error?: string | String;
  recommendations: { label?: String; prompt: String }[];
  onRecommendationSelection(selection: string | String): void;
}) {
  const classes = useStyles();

  const hasNoHistory = messages?.length === 0;

  return (
    <Box className={classes.root}>
      {hasNoHistory && <ChatWarning />}

      <Box className={classes.messages}>
        {messages.map((m, i) => {
          let { content } = m;

          if (
            isLoading &&
            m.role === "assistant" &&
            i === messages.length - 1
          ) {
            content += " ○";
          }

          return <ChatMessage key={m.id} role={m.role} content={content} />;
        })}

        {hasNoHistory && (
          <ChatRecommendations
            recommendations={recommendations}
            onSelection={onRecommendationSelection}
          />
        )}

        {isLoading && <CircularProgress size={20} sx={{ margin: "10px" }} />}
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Box>
  );
}

export default React.memo(ChatMessagesList);
