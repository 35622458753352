import React from "react";
import PropTypes from "prop-types";

import DropDownMenu from "./drop-down-menu";
import NavItem from "./nav-item";
import RootItem from "./root-item";

/**
 * Generic navigation holder, used in header.
 */
class Navigation extends React.Component {
  checkSelected(item) {
    const { pathname } = window.location;
    const rootLink = item.link === "/";
    let selected = pathname === item.link;

    if (!rootLink) {
      selected = pathname.startsWith(item.link);
    }

    return (
      selected ||
      (item.children &&
        item.children.some((child) => this.checkSelected(child)))
    );
  }

  renderRootItem(item) {
    const { target } = this.props;

    const selected = this.checkSelected(item);

    return (
      <RootItem
        key={NavItem.key(item)}
        item={item}
        target={target}
        selected={selected}
      >
        {item.children && (
          <DropDownMenu items={item.children} target={target} />
        )}
      </RootItem>
    );
  }

  render() {
    const { items } = this.props;

    return (
      <nav aria-label="Main menu" className="sde-header-navigation">
        {items.map(this.renderRootItem.bind(this))}
      </nav>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.array,
};

Navigation.defaultProps = {
  items: [],
};

export default Navigation;
