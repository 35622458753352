import React from "react";
import { gettext } from "django-i18n";

import { makeStyles } from "@mui/styles";

import Alert from "@mui/material/Alert";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    margin: 10,
    top: 20,
  },
}));

export default function ChatWarning() {
  const classes = useStyles();

  return (
    <Alert className={classes.root} severity="warning">
      <strong>{gettext("Heads up:")}</strong>{" "}
      {gettext(
        "The AI Navigator assistant may sometimes produce inaccurate information. Please be aware and take caution."
      )}
    </Alert>
  );
}
