import React from "react";
import PropTypes from "prop-types";

import SettingsIcon from "@deprecated/material-ui/svg-icons/action/settings";
import HelpIcon from "@deprecated/material-ui/svg-icons/action/help-outline";

import DropDownMenu from "./drop-down-menu";
import RootItem from "./root-item";
import GlobalSearch from "./global-search";

/**
 * Special global menu used to show items that relate to a user's own account.
 */
const UserMenu = (props) => {
  const { items, target } = props;

  return (
    <nav className="sde-header-user-menu">
      {items.system && (
        <RootItem item={items.system} icon={<SettingsIcon />} target={target}>
          <DropDownMenu items={items.system.items} target={target} />
        </RootItem>
      )}

      {items.support && (
        <RootItem
          item={items.support}
          icon={<HelpIcon data-cy="help-icon" />}
          target={target}
        >
          <DropDownMenu items={items.support.items} target={target} />
        </RootItem>
      )}

      <GlobalSearch />

      {items.account && (
        <RootItem item={items.account} target={target}>
          <DropDownMenu items={items.account.items} target={target} />
        </RootItem>
      )}

      {items.nonaccount && (
        <RootItem item={items.nonaccount} target={target}>
          <DropDownMenu items={items.nonaccount.items} target={target} />
        </RootItem>
      )}
    </nav>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
  }),
  items: PropTypes.shape({
    account: PropTypes.shape({
      items: PropTypes.array,
    }),
    system: PropTypes.shape({
      items: PropTypes.array,
    }),
    support: PropTypes.shape({
      items: PropTypes.array,
    }),
  }),
  target: PropTypes.string,
};

UserMenu.defaultProps = {
  user: {
    first_name: "User",
  },
};

export default UserMenu;
