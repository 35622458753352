import React from "react";

import IconButton from "@deprecated/material-ui/IconButton";

/**
 * Custom material-ui icon button.
 */
const Button = (props) => <IconButton className="sde-icon-button" {...props} />;

export default Button;
