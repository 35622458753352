import React, { useState, useContext } from "react";
import { FormikHelpers, FormikValues, Formik as FormikForm } from "formik";
import { DialogContext } from "../../dialog/DialogContext";
import { PopoverContext } from "../../Popover/PopoverContext";
import FormikContent from "./FormikContent";
import { SdeFormikConfig } from "./interfaces";
import { getSubmitHandler } from "./utils";

interface FormikProps<Values> extends SdeFormikConfig<Values> {
  onSecondarySubmit?: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  void | Promise<any>;
  onSubmitFail?: (formikHelpers: FormikHelpers<Values>) => void;
  onSecondarySubmitFail?: (formikHelpers: FormikHelpers<Values>) => void;
}

const Formik = <Values extends FormikValues = FormikValues, ExtraProps = {}>({
  children,
  initialValues,
  onSecondarySubmit,
  onSecondarySubmitFail,
  onSubmit,
  onSubmitFail,
  ...props
}: FormikProps<Values> & ExtraProps) => {
  const [usingSecondarySubmit, setSecondarySubmit] = useState(false);
  const dialogContext = useContext(DialogContext);
  const popoverContext = useContext(PopoverContext);

  if (!children) {
    return null;
  }

  const handleSubmit = getSubmitHandler<Values>({
    onSubmit: usingSecondarySubmit ? onSecondarySubmit : onSubmit,
    onSubmitFail: usingSecondarySubmit ? onSecondarySubmitFail : onSubmitFail,
  });

  return (
    <FormikForm
      {...props}
      initialValues={initialValues || {}}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <FormikContent
          dialogContext={dialogContext}
          popoverContext={popoverContext}
          formProps={formProps}
          setSecondarySubmit={setSecondarySubmit}
        >
          {children}
        </FormikContent>
      )}
    </FormikForm>
  );
};

// Used to differentiate these components in the debugger
Formik.displayName = "FormikWrapper";

Formik.defaultProps = {
  // enableReinitialize is required so that the dirty prop
  // resets when initialValues is mutated
  enableReinitialize: true,
};

export default Formik;
