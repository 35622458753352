import React, { useEffect } from "react";
import { FormikProps, FormikValues } from "formik";
import { IDialogContext } from "../../dialog/DialogContext";
import { PopoverContext } from "../../Popover/PopoverContext";
import { SdeFormikProps } from "./interfaces";

interface FormikContentProps<Values> {
  children: (props: SdeFormikProps<Values>) => React.ReactNode;
  dialogContext: IDialogContext;
  popoverContext: PopoverContext;
  formProps: FormikProps<Values>;
  setSecondarySubmit: (value: boolean) => void;
}

const FormikContent = <Values extends FormikValues>({
  children,
  dialogContext,
  popoverContext,
  formProps,
  setSecondarySubmit,
}: FormikContentProps<Values>) => {
  useEffect(() => {
    dialogContext.setDirty && dialogContext.setDirty(formProps.dirty);
    popoverContext.setDirty && popoverContext.setDirty(formProps.dirty);
  }, [formProps.dirty]);

  const content = children({
    ...formProps,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => {
      setSecondarySubmit(false);
      return formProps.handleSubmit(e);
    },
    handleSecondarySubmit: (e?: React.FormEvent<HTMLFormElement>) => {
      setSecondarySubmit(true);
      return formProps.handleSubmit(e);
    },
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content}</>;
};

export default FormikContent;
