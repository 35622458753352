import { gettext } from "django-i18n";
import React from "react";

import Ink from "react-ink";
import clsx from "clsx";

import MainMenu from "./navigation/main-menu";
import UserMenu from "./navigation/user-menu";
import SubNavigation from "./navigation/sub-navigation";

/**
 * Global site header. Contains the main navigation.
 */
const Header = (props) => {
  let fab;
  let subNav;

  const subNavItems =
    props.subNavigationItems &&
    props.subNavigationItems.filter((item) => !item.hide);

  if (subNavItems) {
    subNav = (
      <div className="sde-header-bottom">
        <SubNavigation items={subNavItems} />
      </div>
    );
  }

  if (props.fab) {
    fab = <div className="sde-header-actions">{props.fab}</div>;
  }

  const { isLarge, ...otherLogoProps } = props.logo;

  const logoClasses = clsx("sde-header-logo", {
    "is-large": isLarge,
  });

  return (
    <header className="sde-header">
      <div className="sde-container">
        <div className="sde-header-top">
          <a className={logoClasses} href="/">
            <img {...otherLogoProps} alt={gettext("SD Elements Logo")} />
            <Ink />
          </a>

          <MainMenu items={props.navigationItems} target={props.target} />
          <UserMenu items={props.userMenuItems} target={props.target} />
        </div>
        {subNav}
        {fab}
      </div>
    </header>
  );
};

export default Header;
