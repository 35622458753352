import React from "react";

import NavItem from "./nav-item";
import RootItem from "./root-item";

/**
 * Secondary level navigation for the global header.
 */
const SubNavigation = (props) => {
  const renderRootItem = (item) => (
    <RootItem
      key={NavItem.key(item)}
      item={item}
      className="sde-header-subnavigation-item"
    />
  );
  return (
    <nav className="sde-header-subnavigation">
      {props.items.map(renderRootItem)}
    </nav>
  );
};

export default SubNavigation;
