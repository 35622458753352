import React, { MouseEventHandler } from "react";
import classnames from "classnames";

import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import EnhancedMarkdown from "_common/components/markdown/EnhancedMarkdown";

const useStyles = makeStyles(() => ({
  root: {
    background: "#eee",
    display: "flex",
    padding: "10px 15px",
    cursor: "pointer",
    "&:hover": {
      outline: "2px rgb(255, 193, 7) solid",
    },
  },
}));

export default function ChatRecommendation({
  content,
  onClick,
}: {
  content: string | String;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.root)} onClick={onClick}>
      <Typography variant="body2" component="div">
        <EnhancedMarkdown source={content} openLinksInNewTab />
      </Typography>
    </Box>
  );
}
