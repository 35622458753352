import React, { Component } from "react";

/**
 * Global Search overlay component.
 */
class GlobalSearchOverlay extends Component {
  componentDidMount() {
    document.body.setAttribute("style", "overflow: hidden");
  }

  componentWillUnmount() {
    document.body.setAttribute("style", "");
  }

  render() {
    return <div className="sde-global-search-overlay" />;
  }
}

export { GlobalSearchOverlay };
