import React from "react";
import ReactMarkdown from "react-markdown";

/**
 * Markdown component
 */
const Markdown = ({
  renderers = {},
  openLinksInNewTab = false,
  ...otherProps
}) => {
  const renderLink = (props) => {
    let linkProps = props;

    // Don't open anchor links in a new tab or schemes specified in the blacklist
    if (
      openLinksInNewTab &&
      !linkProps.href.startsWith("#") &&
      !linkProps.href.startsWith("mailto")
    ) {
      linkProps = {
        ...linkProps,
        target: "_blank",
        rel: "noopener noreferrer",
      };
    }

    return renderers && renderers.link ? (
      renderers.link(linkProps)
    ) : (
      <a {...linkProps}>{linkProps.children}</a>
    );
  };

  return (
    <ReactMarkdown
      {...otherProps}
      remarkParseOptions={{
        // Allow whitespace in URL links
        pedantic: true,
      }}
      renderers={{
        ...renderers,
        link: renderLink,
      }}
    />
  );
};

Markdown.defaultProps = {
  openLinksInNewTab: false,
  skipHtml: true,
  className: "sde-paper-typography markdown-generated",
};

export default Markdown;
