import React from "react";
import { connect } from "react-redux";
import { omit } from "lodash";
import {
  isPermitted,
  filterVisible,
  assertIfProjectPermsRequired,
} from "./CheckPermsHelpers";

/**
 * Access-control component that allows you to restrict other components from being rendered.
 */
const CheckPerms = (props) => {
  const getChildProps = () => {
    // Some components like dropdowns pass in their own props
    const blacklist = [
      "dispatch",
      "buVisible",
      "children",
      "fallback",
      "features",
      "globalPerms",
      "projectPerms",
      "requiredFeature",
      "requiredPermission",
      "user",
      "requireSuperuser",
      "requiresVisibleBu",
    ];

    return omit(props, blacklist);
  };

  assertIfProjectPermsRequired(props);

  if (isPermitted(props)) {
    return React.cloneElement(props.children, getChildProps());
  }

  return props.fallback || null;
};

// Workaround for using CheckPerms inside of a material-ui dialog
// This allows for focus events and closing
CheckPerms.muiName = "MenuItem";

CheckPerms.componentName = "CheckPerms";

function mapStateToProps(state) {
  return {
    user: state.django.user,
    features: state.django.features || [],
    globalPerms: state.django.globalPerms || [],
    buVisible:
      state.django.bu_visibility && state.django.bu_visibility.bu_visible,
  };
}

// setting the properties below is a workaround for not updating all of
// the imports for all of the components that use isPermitted and
// filterVisible. This is to be done in a follow up MR.
CheckPerms.isPermitted = isPermitted;
CheckPerms.filterVisible = filterVisible;

export default connect(mapStateToProps)(CheckPerms);
