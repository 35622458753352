import { gettext } from "django-i18n";
import React from "react";

import { debounce } from "lodash";

import BackIcon from "@deprecated/material-ui/svg-icons/navigation/arrow-back";
import ClearIcon from "@deprecated/material-ui/svg-icons/content/clear";

import TextField from "@deprecated/material-ui/TextField";

import IconButton from "./buttons/icon";

import Tooltipify from "./tooltipify";

/**
 * Search input used by the Table component.
 */
class Search extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: "",
    };

    this.searchInputRef = React.createRef();

    // Debounce the onChange handler,
    // to not spam the API with lots of queries as the user types.
    this.onChange = debounce((search) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(search);
      }
    }, props.searchDebounce);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { value: internalValue } = this.state;
    const { value } = this.props;

    if (internalValue === value) {
      return;
    }

    this.change(value);
  }

  change(val) {
    const value = val || "";
    const trimmedValue = value.trim();

    this.setState({
      value: trimmedValue.length ? value : "",
    });

    // Only emit change if the TRIMMED values are different
    if (this.state.value.trim() !== value.trim()) {
      this.onChange(value);
    }
  }

  handleBack = () => {
    const handler = this.props.handleBack;

    if (handler) {
      handler();
    }
  };

  handleBlur = () => {
    if (!this.state.value) {
      this.handleBack();
    }
  };

  handleChange = (event) => {
    this.change(event.target.value);
  };

  handleClear = () => {
    this.change(undefined);
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  handleKeyDown = (event) => {
    // Close search, if escape key is hit
    if (event.keyCode === 27) {
      this.handleBack();
    }
  };

  render() {
    const { backToTooltipLabel, searchHint } = this.props;

    const clearButton = !this.state.value ? null : (
      <Tooltipify message={gettext("Clear")} offset={{ top: 0 }}>
        <IconButton
          aria-label={gettext("Clear Search")}
          data-cy="clear-search"
          onClick={this.handleClear}
        >
          <ClearIcon />
        </IconButton>
      </Tooltipify>
    );

    return (
      <span className="sde-paper-title-actions" style={{ display: "flex" }}>
        <Tooltipify message={backToTooltipLabel} offset={{ top: 0 }}>
          <IconButton
            aria-label={gettext("Back")}
            data-cy="hide-search"
            onClick={this.handleBack}
          >
            <BackIcon />
          </IconButton>
        </Tooltipify>

        <TextField
          data-cy="sde-search-field"
          aria-label={gettext("Search")}
          ref={this.searchInputRef}
          value={this.state.value}
          // IMPORTANT: We use onInput instead of onChange because IE11
          // has some timing issues and will cause characters to be lost.
          // This should be resolved in react 0.16+
          onInput={this.handleChange}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          hintText={searchHint}
          fullWidth
          autoFocus
          style={{
            flex: 1,
            marginRight: clearButton ? 0 : "12px",
            width: "auto",
          }}
        />

        {clearButton}
      </span>
    );
  }
}

Search.defaultProps = {
  searchHint: gettext("Search..."),
  backToTooltipLabel: gettext("Back"),
  searchDebounce: 600,
};

export default Search;
