import { getIn, isInputEvent } from "formik";
import { isEqual } from "lodash";

const getError = (field, form) => {
  const { errors, initialValues, submitCount, touched, values } = form;

  const error = getIn(errors, field.name);

  if (!error) {
    return undefined;
  }

  const errorText = Array.isArray(error) ? error[0] : error;

  // Show error if the user has tried to submit the form
  if (submitCount > 0) {
    return errorText;
  }

  const initialValue = getIn(initialValues, field.name);
  const value = getIn(values, field.name);
  const dirty = getIn(touched, field.name) && !isEqual(initialValue, value);

  // Show error if the user has touched the field
  if (dirty) {
    return errorText;
  }

  return undefined;
};

export const mergeProps = ({ field, fieldRef, form, ...otherProps }) => {
  const props = {
    ref: fieldRef,
    ...field,
    ...otherProps,
    onChange: (eventOrValue, ...args) => {
      if (isInputEvent(eventOrValue)) {
        field.onChange(eventOrValue, ...args);
      } else {
        form.setFieldValue(field.name, eventOrValue);
      }
    },
    onBlur: (eventOrValue, ...args) => {
      if (isInputEvent(eventOrValue)) {
        field.onBlur(eventOrValue, ...args);
      } else {
        form.setFieldTouched(field.name);
      }
    },
    // Avoid React warning regarding uncontrolled inputs
    value: field.value === undefined ? "" : field.value,
  };

  const error = getError(field, form);

  if (error) {
    props.errorText = error;
  }

  return props;
};
