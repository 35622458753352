import { gettext } from "django-i18n";

import clsx from "clsx";
import { debounce, isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import Autosuggest from "react-autosuggest";
import isWebKit from "_common/utils/isWebKit";

import { fetchGlobalSearchSuggestions } from "_common/actions";
import Loader from "_common/components/loader";
import waffle from "_common/waffle";
import { GlobalSearchOverlay } from "./global-search-overlay";

const mapSuggestions = (suggestions, buVisible) => {
  const businessUnits = suggestions.find((x) => x.business_units);
  const applications = suggestions.find((x) => x.applications);
  const projects = suggestions.find((x) => x.projects);

  const results = [];

  const transformApp = (item) =>
    buVisible
      ? item
      : {
          ...item,
          business_unit: undefined,
        };

  if (buVisible && businessUnits && businessUnits.business_units.length) {
    results.push({
      title: gettext("Business Units"),
      items: businessUnits.business_units,
    });
  }

  if (applications && applications.applications.length) {
    results.push({
      title: gettext("Applications"),
      items: applications.applications.map(transformApp),
    });
  }

  if (projects && projects.projects.length) {
    results.push({
      title: gettext("Projects"),
      items: projects.projects.map((project) => ({
        ...project,
        application: transformApp(project.application),
      })),
    });
  }

  return results;
};

/**
 * Global Search input component.
 */
class GlobalSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      value: "",
    };

    this.handleSuggestionsFetchRequested = debounce(
      this.handleSuggestionsFetchRequested.bind(this),
      300,
      { leading: false }
    );
  }

  getSuggestionValue = (suggestion) => suggestion.name;

  getSectionSuggestions = (section) => section.items;

  renderEmptyState() {
    const { suggestions, term } = this.props;
    const { focused, value } = this.state;

    const expanded = value || focused;
    const noResults = term && isEmpty(suggestions);

    if (!expanded || !noResults) {
      return null;
    }

    return (
      <div className="sde-global-search-suggestions sde-global-search-empty-state">
        {gettext("No results matching")}: <em>&quot;{term}&quot;</em>
      </div>
    );
  }

  renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      {this.renderLoader(inputProps)}
      {this.renderEmptyState()}
    </div>
  );

  renderLoader(inputProps) {
    const { term } = this.props;
    const loading = term !== inputProps.value && inputProps.value !== "";
    return <Loader loading={loading} />;
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { focused, value } = this.state;

    const expanded = value || focused;

    return (
      <div>
        {expanded && <GlobalSearchOverlay />}
        <div
          aria-label={gettext(
            "Search for Business Units, Applications and Projects"
          )}
          {...containerProps}
        >
          {children}
        </div>
      </div>
    );
  };

  renderSuggestion = (suggestion) => {
    const businessUnit =
      suggestion.business_unit ||
      (suggestion.application && suggestion.application.business_unit);
    const { application } = suggestion;

    return (
      <div>
        <div className="sde-global-search-suggestion-primary">
          <a href={suggestion.url}>{suggestion.name}</a>
        </div>
        {(businessUnit || application) && (
          <div className="sde-global-search-suggestion-secondary">
            {businessUnit && (
              <span>
                <a href={businessUnit.url}>{businessUnit.name}</a>
              </span>
            )}
            {businessUnit && application && <span>&gt;</span>}
            {application && (
              <span>
                <a href={application.url}>{application.name}</a>
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  renderSectionTitle = (section) => <span>{section.title}</span>;

  handleInputBlur() {
    this.setState({ focused: false });
  }

  handleInputChange(e, { newValue }) {
    this.setState({ value: newValue });
  }

  handleInputFocus() {
    this.setState({ focused: true });
  }

  handleInputKeyDown(event) {
    const { value } = this.state;
    const { keyCode, target } = event;

    // Escape pressed when no value entered
    if (!value && keyCode === 27) {
      target.blur();
    }
  }

  handleSuggestionSelected(event, { suggestion }) {
    window.location = suggestion.url;
  }

  handleSuggestionsFetchRequested({ value }) {
    value && this.props.dispatch(fetchGlobalSearchSuggestions(value));
  }

  handleSuggestionsClearRequested = () => {
    this.setState({ value: "" });
    this.props.dispatch(fetchGlobalSearchSuggestions.reset());
  };

  render() {
    if (waffle.flag_is_active("ENABLE_SD_BLUEPRINT")) {
      return null;
    }

    const { suggestions } = this.props;
    const { focused, value } = this.state;

    const expanded = value || focused;

    const inputProps = {
      onBlur: this.handleInputBlur.bind(this),
      onChange: this.handleInputChange.bind(this),
      onFocus: this.handleInputFocus.bind(this),
      onKeyDown: this.handleInputKeyDown.bind(this),
      placeholder:
        (expanded &&
          gettext("Search for Business Units, Applications and Projects")) ||
        gettext("Search..."),
      type: (isWebKit && "text") || "search",
      value,
    };

    const theme = {
      input: clsx("sde-global-search-input", {
        expanded,
      }),
      suggestionsContainer: "sde-global-search-suggestions",
      suggestionsList: "sde-global-search-suggestions-list",
      sectionContainer: "sde-global-search-section",
      sectionTitle: "sde-global-search-section-title",
      suggestion: "sde-global-search-suggestion",
      suggestionHighlighted: "sde-global-search-suggestion-active",
    };

    return (
      <div className="sde-header-navigation-item">
        <div className="sde-global-search-placeholder" />
        <div className="sde-global-search">
          <Autosuggest
            id="globalsearch"
            multiSection
            suggestions={suggestions}
            theme={theme}
            onSuggestionSelected={this.handleSuggestionSelected}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            renderSuggestion={this.renderSuggestion}
            renderSectionTitle={this.renderSectionTitle}
            getSectionSuggestions={this.getSectionSuggestions}
            inputProps={inputProps}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  suggestions: mapSuggestions(
    state.globalSearch.suggestions,
    state.django.bu_visibility && state.django.bu_visibility.bu_visible
  ),
  term: state.globalSearch.term,
});

export default connect(mapStateToProps)(GlobalSearch);
