import React from "react";
import { noop } from "lodash";

export interface PopoverContext {
  setDirty: (value: boolean) => void;
}

export const PopoverContext = React.createContext<PopoverContext>({
  setDirty: noop,
});
