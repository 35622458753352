import React from "react";
import PropTypes from "prop-types";

import CheckPerms from "../../check-perms";

/**
 * Particle component used in header navigation.
 */
class NavItem extends React.Component {
  static key(item) {
    return item.key || item.link || item.to;
  }

  render() {
    const { item } = this.props;
    let { children } = this.props;

    if (item.feature || item.perm || item.requireSuperuser) {
      children = (
        <CheckPerms
          requiredFeature={item.feature}
          requiredPermission={item.perm}
          projectPerms={item.projectPerms}
          requireSuperuser={item.requireSuperuser}
        >
          {children}
        </CheckPerms>
      );
    }

    return children;
  }
}

NavItem.propTypes = {
  item: PropTypes.shape({
    feature: PropTypes.func,
    key: PropTypes.string,
    link: PropTypes.string,
    perm: PropTypes.func,
    projectPerms: PropTypes.object,
    to: PropTypes.string,
    requireSuperuser: PropTypes.bool,
  }).isRequired,
};

export default NavItem;
