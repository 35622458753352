import { gettext } from "django-i18n";
import React, { Component } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import CopyToClipboard from "react-copy-to-clipboard";

import Copy from "@deprecated/material-ui/svg-icons/content/content-copy";

import Tooltipify from "_common/components/tooltipify";

/**
 * Renders code with syntax highlighting and copying.
 */
class Code extends Component {
  constructor() {
    super();
    this.state = { copied: false };
  }

  renderWithCopy(content) {
    const { source } = this.props;
    const { copied } = this.state;

    return (
      <div className="sde-markdown-code-box" style={{ position: "relative" }}>
        {content}
        <Tooltipify
          message={copied ? gettext("Copied!") : gettext("Copy this code")}
          onMouseLeave={() => this.setState({ copied: false })}
        >
          <CopyToClipboard
            text={source}
            onCopy={() => this.setState({ copied: true })}
          >
            <div className="sde-code-copy-icon" data-cy="copy-code">
              <Copy style={{ width: "20px", height: "20px" }} />
            </div>
          </CopyToClipboard>
        </Tooltipify>
      </div>
    );
  }

  renderContent() {
    const { inline, source, language, disableMargin } = this.props;

    const blockStyle = {
      backgroundColor: "#f5f5f5",
      fontFamily: "monospace",
      marginBottom: disableMargin ? undefined : "20px",
      padding: "16px",
      whiteSpace: "pre-wrap",
    };

    const inlineStyle = {
      ...blockStyle,
      display: "inline",
      marginBottom: 0,
      padding: "2px",
    };

    const props = inline
      ? {
          PreTag: "span",
          customStyle: { ...inlineStyle },
        }
      : {
          customStyle: { ...blockStyle },
        };

    return (
      <SyntaxHighlighter
        wrapLongLines
        language={language || "plaintext"}
        {...props}
      >
        {source}
      </SyntaxHighlighter>
    );
  }

  render() {
    const { inline } = this.props;

    const content = this.renderContent();

    return inline ? content : this.renderWithCopy(content);
  }
}

Code.defaultProps = {
  inline: false,
  source: "",
};

export default Code;
