import React, { useState, useMemo, useEffect } from "react";
import { gettext } from "django-i18n";
import { useSelector } from "react-redux";
import storejs from "store";
import { useQuery, useMutation } from "react-query";
import axios from "axios";

import { useChat } from "ai/react";

import { makeStyles } from "@mui/styles";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import MinimizeIcon from "@mui/icons-material/Remove";
import SettingsIcon from "@mui/icons-material/SettingsSuggestOutlined";
import DownloadIcon from "@mui/icons-material/SimCardDownloadOutlined";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import waffle from "_common/waffle";
import { createCachedFetchJWT } from "_common/createServiceClient";

import ChatSettings, { defaultSettings } from "./ChatSettings";
import ChatInput from "./ChatInput";
import ChatMessagesList from "./ChatMessagesList";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: 560,
    height: 770,
    maxWidth: "95%",
    maxHeight: "95%",
    right: 10,
    bottom: 10,
    zIndex: 1201,
    display: "flex",
    flexDirection: "column",
    borderRadius: "2px",
    border: "1px #ccc solid",
    boxShadow: "-1px 1px 1px 1px #e9ebef",
  },
  header: {
    padding: 12,
    borderBottom: "1px #ccc solid",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    cursor: "default",
  },
  loadingBox: {
    flex: "1",
    position: "relative",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Chat({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const [token, setToken] = useState("");
  const [settings, setSettings] = useState(defaultSettings);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    const savedSettings = storejs.get("conjure-chat-settings") || {};

    setSettings({
      ...settings,
      instructions: savedSettings.instructions || "",
    });
  }, []);

  const context: any = useSelector((state: any) => ({
    user: state.user,
    project: state.project?.id ? state.project : undefined,
    task: state.task?.task,
    releaseName: state.releaseName,
  }));

  // This is kinda bad
  // I'm not sure if there's a better way to do this
  const fetchToken = useMemo(
    () =>
      createCachedFetchJWT({
        tokenExpiry: 90 * 1000,
      }),
    []
  );

  const name = window?.django?.user
    ? `${window.django.user.first_name} ${window.django.user.last_name}`
    : "User";

  const release = window?.django?.releaseName || "unknown";

  const messageHistoryQuery = useQuery(
    ["aiChatHistory"],
    async () => {
      const res = await axios.get("/api/ai/chat/history", {
        headers: {
          Authorization: await fetchToken(),
        },
      });

      return res.data.messages;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // Only allow for settings to apply if experimental features are on
  const appliedSettings = waffle.flag_is_active(
    "ENABLE_AI_EXPERIMENTAL_FEATURES"
  )
    ? settings
    : {};

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    stop: stopGeneration,
    setMessages,
    error,
    append,
  } = useChat({
    id: "conjure-test", // TODO: Make this into a proper uid!
    api: "/api/ai/chat",
    headers: {
      Authorization: token,
    },
    body: {
      ...appliedSettings,
      metadata: {
        ...context,
        name,
        release,
        pageName: document.title,
        pageUrl: document.URL,
      },
    },
    initialMessages: messageHistoryQuery.data,
    onError(error) {
      console.error(error);
    },
  });

  const clearMessageHistoryMutation = useMutation(
    ["aiChatHistory"],
    async () =>
      axios.delete("/api/ai/chat/history", {
        headers: {
          Authorization: await fetchToken(),
        },
      }),
    {
      onSuccess: () => {
        setMessages([]);
      },
    }
  );

  let recommendations: { label?: String; prompt: String }[] = [];

  if (context.task && context.task.task_id) {
    recommendations = [
      {
        label: gettext("Tell me about this countermeasure"),
        prompt: gettext("Summarize and explain this countermeasure"),
      },
      {
        label: gettext("Help me write test cases"),
        prompt: gettext(
          "Help me write automated test cases for this countermeasure"
        ),
      },
      {
        prompt: gettext("Help me write secure software with %s").format(
          context.task.task_id
        ),
      },
      {
        prompt: gettext("Show examples for %s").format(context.task.task_id),
      },
    ];
  } else if (context.project && context.project.id) {
    recommendations = [
      {
        prompt: gettext("Tell me about this project"),
      },
      {
        prompt: gettext("How do I secure my software?"),
      },
      {
        prompt: gettext("Help me write automated test cases"),
      },
      {
        prompt: gettext("Tell me a cybersecurity joke!"),
      },
    ];
  } else {
    recommendations = [
      {
        prompt: gettext("What can you do?"),
      },
      {
        prompt: gettext("How do I secure my software?"),
      },
      {
        prompt: gettext("Tell me a cybersecurity joke!"),
      },
    ];
  }

  let displayedError;

  if (error?.message) {
    displayedError =
      error.message.length > 300
        ? gettext(
            "An error has occured. Please contact support for assistance."
          )
        : error.message;
  } else if (messageHistoryQuery.isError && messages.length === 0) {
    displayedError = gettext("Unable to fetch message history.");
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Box className={classes.header}>
        <Typography sx={{ flex: 1 }} className={classes.logo}>
          {gettext("Navigator")} ✨
        </Typography>

        {messages.filter((m) => m.role !== "system").length > 0 && (
          <>
            <Tooltip title={gettext("Clear Messages")}>
              <IconButton
                size="small"
                onClick={() => {
                  stopGeneration();
                  clearMessageHistoryMutation.mutate();
                }}
              >
                <DeleteSweepIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={gettext("Export Conversation")}>
              <IconButton
                size="small"
                onClick={() => {
                  const jsonString = JSON.stringify(messages, null, 2);

                  const blob = new Blob([jsonString], {
                    type: "application/json",
                  });

                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = "aiscc-conversation-export.json";

                  document.body.appendChild(link);

                  link.click();

                  document.body.removeChild(link);
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {waffle.flag_is_active("ENABLE_AI_EXPERIMENTAL_FEATURES") && (
          <Tooltip title={gettext("Settings")}>
            <IconButton size="small" onClick={() => setIsSettingsOpen(true)}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={gettext("Close Chat")}>
          <IconButton size="small" onClick={onClose}>
            <MinimizeIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {(messageHistoryQuery.isLoading ||
        clearMessageHistoryMutation.isLoading) && (
        <Box className={classes.loadingBox}>
          <CircularProgress size={40} />
        </Box>
      )}

      {!messageHistoryQuery.isLoading &&
        !clearMessageHistoryMutation.isLoading && (
          <ChatMessagesList
            messages={messages}
            isLoading={isLoading}
            error={displayedError}
            recommendations={recommendations}
            onRecommendationSelection={async (selection: string) => {
              setToken(await fetchToken());

              append({
                id: "init",
                role: "user",
                content: selection,
              });
            }}
          />
        )}

      <ChatInput
        input={input}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
        onStop={stopGeneration}
        onSubmit={async (e) => {
          setToken(await fetchToken());

          handleSubmit(e);
        }}
      />
      {isSettingsOpen && (
        <Paper
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <Box className={classes.header}>
            <Typography sx={{ flex: 1 }} className={classes.logo}>
              {gettext("Navigator Settings")} ✨
            </Typography>

            <Tooltip title={gettext("Back to Chat")}>
              <IconButton size="small" onClick={() => setIsSettingsOpen(false)}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={gettext("Close Chat")}>
              <IconButton size="small" onClick={onClose}>
                <MinimizeIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <ChatSettings
            settings={settings}
            onSettingsChange={(newSettings) => {
              storejs.set("conjure-chat-settings", {
                instructions: newSettings.instructions,
              });

              setSettings({
                ...settings,
                ...newSettings,
              });
            }}
          />
        </Paper>
      )}
    </Paper>
  );
}
