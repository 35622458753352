import React from "react";
import { gettext } from "django-i18n";

import { makeStyles } from "@mui/styles";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles(() => ({
  root: {
    borderTop: "1px #ccc solid",
    position: "relative",
  },
  input: {
    padding: 20,
  },
  stopGeneration: {
    background: "#fff !important",
    position: "absolute",
    top: -16,
    left: "50%",
    transform: "translate(-50%, 0)",
    cursor: "pointer",
    zIndex: 1,
    borderRadius: 4,
    "&:hover": {
      borderColor: "rgb(255, 193, 7)",
    },
  },
}));

export default function ChatInput({
  input,
  isLoading,
  onSubmit,
  onStop,
  handleInputChange,
}: {
  input: string;
  isLoading: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onStop(): void;
  handleInputChange(
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ): void;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isLoading && (
        <Button
          className={classes.stopGeneration}
          onClick={() => {
            onStop();
          }}
          variant="outlined"
          color="grey60"
          size="small"
        >
          Stop Generation
        </Button>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();

          onSubmit(e);
        }}
      >
        <Input
          autoFocus
          className={classes.input}
          placeholder={gettext("Talk to the assistant...") as string}
          fullWidth
          readOnly={isLoading}
          onChange={handleInputChange}
          size="small"
          value={input}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={gettext("Send message") as string}
                edge="end"
                type="submit"
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    </Box>
  );
}
